import React, {useState,useEffect, useContext} from 'react';

import AuthService from "../services/AuthService";
import '../index.css';
import { store } from '../redux/store';
import { Hidden } from '@mui/material';
import { Console } from 'console';
import $ from 'jquery';
import {IUser} from "../models/IUser";
import {AppStateContext, AppConstStateActions} from '../redux/AppStore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {showErrorInfo, PCModalStyle, MobileModalStyle, PolicyText} from "../components/common/Helpers";
import {useSearchParams,  useNavigate , Link } from "react-router-dom";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface IState{
    resetPassword : {
        password : '',
        password_confirmation : ''
    },
    user : IUser
}

interface IProps{}

let NewPasswordForm:React.FC<IProps> = () => {
    const appStateContext = useContext(AppStateContext);

    if(!appStateContext) {
      throw Error('CurrentContext must be used whith a CounterProvider.');
    }
  
    const { appDatas, appAction } = appStateContext;
    let [state , setState] = useState<IState>({
        resetPassword : {
            password : '',
            password_confirmation : ''
        },
        user : {
            email : '',
            password : ''
        }
    });

    let [text, setText] = useState("none");
    let [resendtxt, setResendTxt] = useState("none");
    const [btnColor, setBtnColor] = useState("#444444ab");
    const [disabled, setDisabled] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    let [login_error, setLoginError] = useState("none");
    const [open, setOpen] = React.useState(false);
	const navigate = useNavigate();
    const [showLoginForm, setShowLoginForm] = useState("none");
    const [showNewPasswordForm, setShowNewPasswordForm] = useState("block");
    const [showTitle, setShowTitle] = useState("新しいパスワードを設定してください。");

    const handleClose = () => setOpen(false);
    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            resetPassword : {
                ...state.resetPassword,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            },
            user : {
                email : '',
                password : ''
            }
        });
    };

    let updateLoginInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            resetPassword : {
                password : '',
                password_confirmation : ''
            },
            user : {
                ...state.user,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    };

    // resend code
    let verifyResend = () => {
        
        let data = {};
        data["email"] =  localStorage.getItem("login_email");
        data["password"] = localStorage.getItem("login_pwd");
        AuthService.resend(data).then((response)=>{
            console.log(response);
            if(response.data.status == "200"){
                // setText("none");
                setResendTxt("block");
                $(".factor_form").css("text-align", "center");
                $(".factor_form .resend").remove();
                $(".factor_form .err_txt").remove();
                $(".factor_form").append("<span class='resend' value="+text+">コードが再送信されました</span>");
                setText("none");
                $(".factor_form #err_txt").css("display", "none");
                $('#two-factor-code').val('');
                // window.location.href = "/verify";
            }
        }).catch((error:any) => {
            console.log(error);
        });
    }
    const  policyModal = (
		<div className="row">
		<div className="col-12">
			<div className="modal-content">
						<div className="modal-header">
						<h3 className="modal-title" id="exampleModalLabel">SESMart企業用　利用規約</h3>
						<button type="button" className="modal_close" onClick={handleClose}>X</button>
						{/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button> */}
						</div>
						{PolicyText}
						<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Close</button>
						</div>
					</div>
		</div>
	</div>
	)

    //confirm two verification code
    let store = (event : any):void => {
         // click event type declare in React
        event.preventDefault();
        setBtnColor("#44444459");
        setDisabled(true);
        let datas = {};
		datas["password"]= state.resetPassword.password;
		datas["password_confirmation"] = state.resetPassword.password_confirmation;
        datas["email"] = searchParams.get("email")!;
        datas["token"] = searchParams.get("token")!;
        
        $(".err_txt, .success_txt").empty();
        AuthService.newPasswordChange(datas).then((response) => {
            console.log(response);
            setBtnColor("#444444ab");
            setText("");
            setDisabled(false);
            if(response.status == 200){
                $(".factor_form").append("<p class='success_txt'>パスワードを入力しログインしてください。</p>"); 
                setShowLoginForm("block");
                setShowNewPasswordForm("none");
                setShowTitle(response.data.message);
                setState({
                    resetPassword : {
                        password : '',
                        password_confirmation : ''
                    },
                    user : {
                        ...state.user,
                        email : searchParams.get("email")! 
                    }
                });
            }else{
                $(".factor_form").append("<p class='err_txt'>"+response.data.message+"</p>");    
            }
        }).catch((error) => {
            console.log(error);
            if(error.response.data.errors !== undefined){
                var alertMessage = "";
                $.each(error.response.data.errors.password, function(index, item) {
                    alertMessage += item + "<br/>";
                });
    
                $(".factor_form").append("<p class='err_txt'>"+alertMessage+"</p>");    
            }else{
                $(".factor_form").append("<p class='err_txt'>"+error.response.data.message+"</p>");    
            }
           
            setDisabled(false);
            setText("block");
            setBtnColor("#444444ab");
        })
    };


    let login =  (event:any) =>{// click event type declare in React
        event.preventDefault();
		setBtnColor("#44444459");
		setDisabled(true);

		let datas = {};
		datas["email"]= state.user.email;
		datas["password"] = state.user.password;

        AuthService.login(datas).then((response) => {
			setLoginError("none");
			setBtnColor("#444444ab");
			$(".login-form .form #star").remove();

            if(response.data.status == 200){
                setLoginError("none");
                // localStorage.setItem("token", response.data.token);
                localStorage.setItem("login_email", datas["email"]);
				localStorage.setItem("login_pwd", datas["password"]);
				// localStorage.setItem("datas", JSON.stringify(response.data.datas));
				// window.location.href = "/verify";
				navigate('/verify');
                // AuthService.resourceLogin(response.data.token, "/cases/list");
            }else if(response.data.status == 401){

				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>"+response.data.message+"</span>"); 
			}else{
				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>メールアドレスまたはパスワードが間違っています。</span>"); 
            }
            
        }).catch((error) => {
			setDisabled(false);
            setLoginError("block");
            // if(error.response.data){
            //     showErrorInfo(error.response.data);
            // }

        })
    };

    return(
        <React.Fragment>
            <div  className="g-0" style={{overflow:"hidden"}}> 
                <div className="card">
                <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className=" xs-hidden">
                        <img src="/images/login.png" alt="profile-img"  style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
                        <div className="login-content" style={{zIndex: 999}}>即戦力人材を活用して、<br/>迅速な課題解決が<br/>可能になります。</div>
                    </Col>
                      <Col  xxl={6} xl={6} md={6} sm={6} xs={12}>
                            <div className="card-body two-factor-form ">
                                <Row>
                                    <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                        <div className="mt_10 border-0">
                                            <div className="two-factor-header text-center border-0" style={{backgroundColor : "#FFFF"}}>
                                                <h1 className="p-3">SESMart</h1>
                                                <p>{showTitle}</p>
                                            </div>
                                            <div className="card-body two-factor-form">
                                                <div className="factor_form" style={text=="none" ? {display:resendtxt} : {display:text}}></div>
                                                <form className="form"  id="new_pwd_form" style={{display:showNewPasswordForm}}>
                                                    {/* <span className="two-factor-code-error" ></span> */}
                                                        <div className="form-group text-center">
                                                            <input type="password" name='password' id= 'password' onChange={updateInput} value={state.resetPassword.password} placeholder="新パスワード"  className="form-control mb-3 two_factor_code"  required autoFocus/>
                                                        </div>
                                                        <div className="form-group text-center">
                                                            <input type="password" name='password_confirmation' id= 'password_confirmation' onChange={updateInput} value={state.resetPassword.password_confirmation} placeholder="新パスワード確認"  className="form-control mb-3 two_factor_code"  required />
                                                        </div>
                                                        <Row  className="text-center mt-2">
                                                            <Col className="mt-3" xxl={{ span: 4, offset: 2 }} xl={{ span: 4, offset: 2 }} md={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                                                                <button type="button" onClick={store} className="login-btn" style={{backgroundColor: btnColor}} disabled={disabled}>更新</button>
                                                            </Col>
                                                            <Col className="mt-3" xxl={{ span: 5, offset: 0 }} xl={{ span: 5, offset: 0 }} md={{ span: 12, offset: 0 }} sm={{ span: 12, offset: 0 }} xs={{ span: 12, offset: 0 }}>
                                                                <a href="/" style={{fontSize:"15px",color : "#82817F"}}>ログインページへ</a>
                                                            </Col>
                                                        </Row>
                                                       
                                                </form>

                                                <form className="form text-center" id="login_form" style={{display:showLoginForm}}>
                                                    <span className="login-error" style={{display:login_error}}></span>
                                                    <div className="form-group text-center">
                                                        <input type="text"  name='email' onChange={updateLoginInput} value={state.user.email} className="form-control mb-3" placeholder="メールアドレス" autoComplete="email" autoFocus/>
                                                    </div>
                                                    <div className="form-group text-center">
                                                        <input type="password" name="password" onChange={updateLoginInput} className="form-control mb-3" placeholder="パスワード" value={state.user.password} />
                                                    </div>
                                                    <div className="form-group text-center mb_25" style={{fontSize:"12px"}}>
                                                        <input className="ml_20" type="checkbox" value="" id="privacy" name ="privacy" style={{marginRight:"8px",marginTop:"5px"}}required/> 
                                                        <a 
                                                            href="" onClick={(event) => {
                                                            event.preventDefault();
                                                            setOpen(true);
                                                        }}>SESMart利用規約・個人情報</a>に関する同意書に同意する。 
                                                    </div>
                                                    <div className="form-group text-center mt-3">
                                                        <button type="button"  onClick={login} className="login-btn" style={{backgroundColor: btnColor}} 
                                                        disabled={disabled}>ログイン</button><br/>
                                                    </div>
                                                   
                                                    {/* <div className="form-group text-center security mt-5" style={{fontSize: "12px"}}>
                                                        <a  href="https://floboard.co.jp/www/index.php/company/privacy" target="_blank">個人情報保護方針 </a>
                                                        <br/> 
                                                        <a href="https://floboard.co.jp/www/index.php/company/security"  target="_blank">情報セキュリティ方針</a>
                                                    </div> */}
                                                </form>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    </Col>
                </Row>
                </div>
            </div>   

              <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'none', sm: 'block' },
					}}
                >  
                    <Box sx={PCModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>

				<Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'block', sm: 'none' },
					}}
                >  
                    <Box sx={MobileModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>                                 
        </React.Fragment>
    )
};
export default NewPasswordForm;