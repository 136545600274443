import React, {useState, useContext} from 'react';
import AuthService from "../services/AuthService";
import '../index.css';
import { store } from '../redux/store';
import { Hidden } from '@mui/material';
import { Console } from 'console';
import $ from 'jquery';

import {AppStateContext, AppConstStateActions} from '../redux/AppStore';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {showErrorInfo, inputNumber, PCModalStyle, MobileModalStyle, PolicyText} from "../components/common/Helpers";
import { useNavigate , Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

interface IProps{}
interface IState{
    user : {
        name_jp : '',
        kana_name : '',
        email : '',
        password : '',
        login_password : '',
        tel_no : '',
        company : '',
        dept_name : '',
        position : '',
        respond_area : '',
        type : ''
    }
}

let SignUpForm:React.FC<IProps> = () => {
    const appStateContext = useContext(AppStateContext);

    if(!appStateContext) {
      throw Error('CurrentContext must be used whith a CounterProvider.');
    }
    const navigate = useNavigate();
    const { appDatas, appAction } = appStateContext;
    let [email, setEmail] = useState("");
    let [display, setDisplay] = useState("none");
    let [text , setText] = useState("");
    const [btnColor, setBtnColor] = useState("#444444ab");
    const [disabled, setDisabled] = React.useState(false);
    const [showLoginForm, setShowLoginForm] = useState("none");
    const [showSignUpForm, setSignUpForm] = useState("block");
    const [open, setOpen] = React.useState(false);
    const handleClose = () => setOpen(false);
    let [login_error, setLoginError] = useState("none");

    let [state , setState] = useState<IState>({
        user : {
            name_jp : '',
            kana_name : '',
            email : '',
            password : '',
            login_password : '',
            tel_no : '',
            company : '',
            dept_name : '',
            position : '',
            respond_area : '',
            type : ''
        }
    });

    let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            user : {
                ...state.user,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    };
    let updateLoginInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
        setState({
            user : {
                ...state.user,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
    };

    
    const onSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        setState({
            user : {
                ...state.user,
                [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
            }
        });
     };

     const handleRadio = function(e:any){
        let isChecked = e.target.checked;
        let value = e.target.value;
        if(isChecked === true){
            setState({
                user : {
                    ...state.user,
                    [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
                }
            });          
        }
    }     

    const  policyModal = (
		<div className="row">
		<div className="col-12">
			<div className="modal-content">
						<div className="modal-header">
						<h3 className="modal-title" id="exampleModalLabel">SESMart企業用　利用規約</h3>
						<button type="button" className="modal_close" onClick={handleClose}>X</button>
						{/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button> */}
						</div>
						{PolicyText}
						<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleClose}>Close</button>
						</div>
					</div>
		</div>
	</div>
	)

    //confirm two verification code
    let store = (event : any):void => {
         // click event type declare in React
        event.preventDefault();
        setBtnColor("#44444459");
        setDisabled(true);
      
        AuthService.signup(state.user).then((response) => {
            console.log(response);
            setBtnColor("#444444ab");
            setText("");
            setDisabled(false);
            if(response.status == 200){
                setShowLoginForm("block");
                setSignUpForm("none");
                setState({
                    user : {
                        ...state.user,
                        email : response.data.email
                    }
                });
            }

        }).catch((error) => {
            if(error.response.data){
                showErrorInfo(error.response.data);
            }
            setDisabled(false);
            setBtnColor("#444444ab");
        })
    };

    let login =  (event:any) =>{// click event type declare in React
        event.preventDefault();
		setBtnColor("#44444459");
		setDisabled(true);

		let datas = {};
		datas["email"]= state.user.email;
		datas["password"] = state.user.login_password;

        AuthService.login(datas).then((response) => {
			setLoginError("none");
			setBtnColor("#444444ab");
			$(".login-form .form #star").remove();

            if(response.data.status == 200){
                setLoginError("none");
                localStorage.setItem("login_email", datas["email"]);
				localStorage.setItem("login_pwd", datas["password"]);
				navigate('/verify');
            }else if(response.data.status == 401){

				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>"+response.data.message+"</span>"); 
			}else{
				setBtnColor("#444444ab");
                setLoginError("block");
				setDisabled(false);
                $(".login-error").css("display", "block !important");
				$(".login-form .form").prepend("<span id='star'>メールアドレスまたはパスワードが間違っています。</span>"); 
            }
            
        }).catch((error) => {
			setDisabled(false);
            setLoginError("block");
            if(error.response.data){
                showErrorInfo(error.response.data);
            }

        })
    };
    return(
        <React.Fragment>
            <div  className="g-0" style={{overflow:"hidden"}}> 
                <div className="card">
                <Row>
                    <Col xxl={6} xl={6} md={6} sm={6} xs={12} className=" xs-hidden">
                        <img src="/images/login.png" alt="profile-img"  style={{width: '100%', height:'100%', objectFit: 'cover'}}/>
                        <div className="login-content" style={{zIndex: 999}}>即戦力人材を活用して、<br/>迅速な課題解決が<br/>可能になります。</div>
                    </Col>
                      <Col  xxl={6} xl={6} md={6} sm={6} xs={12}  style={{display:showSignUpForm}}>
                            <div className="card-body two-factor-form">
                                <Row>
                                    <Col xxl={{ span: 10, offset: 1 }} xl={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                        <div className="card border-0">
                                            <div className="card-header header text-center border-0" style={{backgroundColor : "#FFFF"}}>
                                                <h1 className="p-1">SESMart</h1>
                                                <p style={{marginBottom: "0rem"}}>Welcome to the SES Sales System</p>
                                            </div>
                                            <div className="card-body two-factor-form sign-up-form">
                                                <form className="form" >
                                                    <Row className="mb-3">
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="name"   className="input-required">アカウントID（メールアドレス）:</label>
                                                                <input type="email"  name='email' maxLength={50} onChange={updateInput} value={state.user.email} className="form-control" placeholder="メールアドレス" autoComplete="email" autoFocus/>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="password"   className="input-required">パスワード:</label>
                                                                <input type="password" name="password" maxLength={30} onChange={updateInput} className="form-control" placeholder="パスワード" value={state.user.password} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="name_jp"   className="input-required float-left">漢字氏名:</label>
                                                                <input type="text"  name='name_jp' maxLength={40} onChange={updateInput} value={state.user.name_jp} className="form-control" placeholder="漢字氏名" autoComplete="email" autoFocus/>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="kana_name"   className="input-required">カタカナ氏名:</label>
                                                                <input type="text" name="kana_name" maxLength={40} onChange={updateInput} className="form-control" placeholder="カタカナ氏名" value={state.user.kana_name} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="company" >会社名(自営業の場合、屋号または個人名を入力してください。):</label>
                                                                <input type="text" maxLength={70} name="company" onChange={updateInput} className="form-control" placeholder="会社名" value={state.user.company} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="tel_no"   className="input-required">電話番号:</label>
                                                                <input type="number" name="tel_no" maxLength={20} onKeyPress={inputNumber} onChange={updateInput} className="form-control" placeholder="電話番号" value={state.user.tel_no} />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="dept_name" >部門名:</label>
                                                                <input type="text" name="dept_name" maxLength={80} onChange={updateInput} className="form-control" placeholder="部門名" value={state.user.dept_name} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mb-3">
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="position" >役職:</label>
                                                                <select name="position"  className="form-control"  onChange={onSelect} value={state.user.position} >
                                                                    <option value="担当">担当</option>
                                                                    <option value="主任">主任</option>
                                                                    <option value="課長">課長</option>
                                                                    <option value="部長">部長</option>
                                                                    <option value="取締役">取締役</option>
                                                                    <option value="副社長">副社長</option>
                                                                    <option value="社長">社長</option>
                                                                </select>
                                                            </div>
                                                        </Col>
                                                        <Col xxl={6} xl={6} md={6} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <label htmlFor="dept_name" >担当エリア:</label>
                                                                <input type="text" name="respond_area" maxLength={80} onChange={updateInput} className="form-control" placeholder="担当エリア" value={state.user.respond_area} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="mt-4">
                                                        <Col xxl={12} xl={12} md={12} sm={12} xs={12}  className="input-box">
                                                            <div className="form-group">
                                                                <input type="radio" className="mr_0" id="radio_type_case" name="type" value="cases" onChange={e => handleRadio(e)} />
                                                                <label htmlFor="type" className="mr-3">案件を登録したいです。(要員は全部見れます。)</label><br/>
                                                                <input type="radio" className="mr_0" id="radio_type_person" name="type" value="persons" onChange={e => handleRadio(e)} />
                                                                <label htmlFor="radio_type_base" className="mr-3">要員を登録したいです。(案件は全部見れます。)</label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="text-center">
                                                        <Col xxl={{ span: 4, offset: 2 }} xl={{ span: 4, offset: 2 }} md={{ span: 4, offset: 1 }} sm={{ span: 12,offset: 0}} xs={{ span: 3, offset: 0}}  className="input-box">
                                                            <button type="button" onClick={store} className="login-btn mt-3" style={{backgroundColor: btnColor, margin:'unset'}} disabled={disabled}>登録</button>                                                      
                                                        </Col>
                                                        <Col  xxl={{ span: 4, offset: 0}}  xl={{ span: 4, offset: 0}} md={{ span: 4, offset: 2}} sm={{ span: 12, offset: 0 }} xs={{ span: 3, offset: 3}}  className="input-box">
                                                            <div className="pull-left">
                                                                <button type="button" onClick={() => navigate("/")} className="login-btn float-left mt-3" style={{margin:'unset'}} disabled={disabled}> キャンセル</button>
                                                            </div>
                                                        </Col>
                                                    </Row>   
                                                </form>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                    </Col>

                    <Col  xxl={6} xl={6} md={6} sm={6} xs={12} style={{display:showLoginForm}}>
                        <div className="card-body login-form login-container">
                        <Row>
                            <Col xxl={{ span: 8, offset: 2 }} xl={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={{ span: 10, offset: 1 }}>
                                <div className="card mt_10 border-0">
                                    <div className="card-header header text-center border-0" style={{backgroundColor : "#FFFF"}}>
                                        <h1 className="p-3">SESMart</h1>
                                        <p>Welcome to the SES Sales System</p>
                                    </div>
                                    <div className="card-body">
                                        <form className="form text-center" id="login_form" >
                                            {/* <span className="login-error" style={{display:login_error}}></span> */}
                                            <div className="form-group text-center">
                                                <input type="text"  name='email' onChange={updateLoginInput} value={state.user.email} className="form-control mb-3" placeholder="メールアドレス" autoComplete="email" autoFocus/>
                                            </div>
                                            <div className="form-group text-center">
                                                <input type="password" name="login_password" onChange={updateLoginInput} className="form-control mb-3" placeholder="パスワード" value={state.user.login_password} />
                                            </div>
                                            <div className="form-group text-center mb_25" style={{fontSize:"12px"}}>
                                                <input className="ml_20" type="checkbox" value="" id="privacy" name ="privacy" style={{marginRight:"8px",marginTop:"5px"}}required/> 
                                                <a 
                                                    href="" onClick={(event) => {
                                                    event.preventDefault();
                                                    setOpen(true);
                                                }}>SESMart利用規約・個人情報</a>に関する同意書に同意する。 
                                            </div>
                                            <div className="form-group text-center mt-3">
                                                <button type="button"  onClick={login} className="login-btn" style={{backgroundColor: btnColor}} 
                                                disabled={disabled}>ログイン</button><br/>
                                            </div>
                                            <div className="form-group text-center security mt-5" style={{fontSize: "12px",fontWeight: 700}}>
												<a href={`/resetpassword`}>
												パスワードを忘れた方 
												</a>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Col>
                       </Row>
                        </div>
                    </Col>

                </Row>
                </div>
            </div>      

             <Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'none', sm: 'block' },
					}}
                >  
                    <Box sx={PCModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>

				<Modal
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="keep-mounted-modal-title"
                    aria-describedby="keep-mounted-modal-description"
					sx={ {
						display: { xs: 'block', sm: 'none' },
					}}
                >  
                    <Box sx={MobileModalStyle}>
                          {policyModal}
                    </Box>
                </Modal>                                
        </React.Fragment>
    )
};
export default SignUpForm;