import Dropdown from 'react-bootstrap/Dropdown';
import AuthService from "../../services/AuthService";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import React, {useState , useEffect} from 'react';
import {UserModel} from "../../models/IUser";
import UserService from "../../services/UserService";
import ResourceService from "../../services/ResourceService";
import ResourceImg from '../../components/common/ResourceImg';
import $ from 'jquery';
import {showErrorInfo,  inputNumber, PCModalStyle, MobileModalStyle} from "../../components/common/Helpers";

interface IState{
  user_model : UserModel;
}

interface IProps{}

let DropDownList:React.FC<IProps> = () =>{

// const DropDownList = () => {
 
  const logout =  function(e:any){
    AuthService.logout();
    setTimeout(()=>{
        window.location.href = "/";
    }, 1000*5);
  }
  const userStr = localStorage.getItem("user_name"); 
  let username  = ""; // retrieve stored user information from local storage
  if (userStr){
    // const user = JSON.parse(userStr);
    // username = user.name_jp;
    username = userStr;
  } 

  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false); 
  let [show_profile, setShowProfile] = useState("none");
  let [drop_profile, setDropProfile] = useState("block");
  let [photo_name, setPhotoName] = useState("");

//   const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 800,
//     height: 700,
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };
  const login_user =   JSON.parse(localStorage.getItem("user") || '{}');
  let [case_type, checkCaseType] = useState(true);
  let [change_role, changeRole] = useState(false);

  let [state, setState] = useState<IState>({
    user_model : {
        user_id : login_user.user_id,
        name_jp : login_user.name_jp,
        kana_name : login_user.kana_name,
        email : login_user.email,
        photo : login_user.photo,
        password : "",
        tel_no : login_user.tel_no,
        privilege_grp_cd : login_user.privilege_grp_cd,
        company : login_user.company,
        dept_name : login_user.dept_name,
        position : login_user.position,
        respond_area : login_user.respond_area,
        role : login_user.role,
        admission_date : login_user.admission_date,
        withdraw_date : login_user.withdraw_date,
        withdraw_reason : login_user.withdraw_reason,
        memo : login_user.reason
    }
});
let { user_model } = state;

  const showProfile = function(){
    $(".error").empty();
    UserService.showUser(login_user.user_id).then((response) => {  
      setState({
        ...state,
        user_model : {
          user_id : response.data.user_id,
          name_jp : response.data.name_jp,
          kana_name : response.data.kana_name,
          email : response.data.email,
          photo : response.data.photo,
          password : "",
          tel_no : response.data.tel_no,
          privilege_grp_cd : response.data.privilege_grp_cd,
          company : response.data.company,
          dept_name : response.data.dept_name,
          position : response.data.position,
          respond_area : response.data.respond_area,
          role : response.data.role,
          admission_date : response.data.admission_date,
          withdraw_date : response.data.withdraw_date,
          withdraw_reason : response.data.withdraw_reason,
          memo : response.data.reason
      }
    });  

    if(response.data.role == 7 ){
        checkCaseType(true);
    }else{
        checkCaseType(false);
    }

    if(response.data.photo != null){
      setShowProfile("block");
      setDropProfile("none");
    }
    setOpen(true);
    }).catch((error) => {
      console.log(error);

    })
   
     
  }

  const storeProfile = function (e:any) {
    e.preventDefault();
    UserService.updateProfile(state.user_model , photo_name).then((response) => {  
      if(response.data.success){
        if(change_role === true){
            alert("種類が変わったのでもう一度ログインしてください。");
            AuthService.logout();
        }else{
            alert("プロファイル内容を更新しました。");
            localStorage.setItem("user_name",  response.data.info!.name_jp);
            localStorage.setItem("org_name",  response.data.info!.company);
            setOpen(false);
            window.location.reload();
        }
       
      }
    }).catch((error) => {
      if(error.response.status == 403){
        alert(error.response.data.message);
        }
        if(error.response.data){
            showErrorInfo(error.response.data);
        }
    })
  }

  const upload_profile = function (e:any) {
    e.preventDefault();

  }

  const profileExplorer = function (e:any) {
    e.preventDefault();
    $('#selectprofile').click();
  }
 const changeProfile = function(e:any) {
  let file_name = e.target.files[0].name;
    profile_upload(e.target.files[0],file_name);
    e.target.value = null;
};
 const profile_upload = function(file_obj:any, name:any) {
    ResourceService.apiUpload(file_obj, 'profiles/', name).then((res:any) => {
    setShowProfile("block");
    setDropProfile("none");
    setPhotoName(name);
    localStorage.setItem('profile_image', name);
    $(".img-content img").attr("src" , ResourceService.url(`profiles/${name}`))
  });
  
  // if(file_obj.size > 1000000) {
  //    alert("Please upload file less than 1MB. Thanks!!");
  //  }else{
  //      if(file_obj != undefined) {
  //         var form_data = new FormData();   
  //         form_data.append("profile", file_obj);    
  //         let url = "<?php echo route('api.user.profileupload'); ?>";
  //         return storeToDb(url, form_data, function(result){
  //             let name = result.info[0];
  //             let file_type = result.info[1];
  //             let txt = "<img src='"+profile_route+"/"+name+"' id='upload_img_0' alt='"+name+"' style='width:10%;margin:10px'>";
  //             $(".img-content").show().empty().html(txt);
  //             $(".uploaded_profile").val(name);
  //             $(".drop_profile").hide();
  //         });
  //     }
  //  }
}

  let editProfile = function(e:any) {
    e.preventDefault();
    setShowProfile("none");
    setDropProfile("block");
  }

  let updateInput = (event:React.ChangeEvent<HTMLInputElement>):void => { // change event type declare in React
    setState({
        ...state,
        user_model : {
            ...state.user_model,
            [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
        }
    });   
};

const onSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setState({
        ...state,
        user_model : {
            ...state.user_model,
            [event.target.name] : event.target.value  // input name 'username' set value 'event target value'
        }
    });
 };
 const handleRadio = function(e:any){
    let isChecked = e.target.checked;
    let value = e.target.value;
    changeRole(true);
    if(isChecked === true){
        setState({
            user_model : {
                ...state.user_model,
                [e.target.name] : e.target.value  // input name 'username' set value 'event target value'
            }
        });          
    }
}  

const profile_modal = (
    <div>
         <div className="row">
            <div className="col-6">
                <h3>プロファイル変更</h3>
            </div>
            <div className="col-6">
                    <button type="button" className="modal_close" onClick={handleClose}>X</button>
            </div>            
        </div>
        <form className="form">
            <input type="hidden" name="user_id" value={user_model["user_id"]} />
                <div className="row">
                <div className="col-12 input-box">
                    <input type="hidden" name="photo" value={user_model['photo']} className="uploaded_profile" />
                        <div className="img-content show_profile" style={{display: show_profile }}>
                            <button className="edit_btn" onClick={editProfile}><i className="fas fa-edit"></i></button>
                            <ResourceImg remoteSrc={(localStorage.getItem('profile_image') != undefined  && localStorage.getItem('profile_image') !== null
                            && localStorage.getItem('profile_image') != '')?`profiles/${localStorage.getItem('profile_image')}`: ''} src={'images/businessman.jpg'}  className="img-circle" alt="icons"  />
                        </div>
                        
                        <div id="drop_file_zone" className="drop_profile" style={{display: drop_profile }} data-id="1" onDrop={upload_profile}>
                            <div id="drag_upload_file">
                                <p>顔写真をドラッグしてください。</p>
                                <p>または</p>
                                <p><input type="button" value="顔写真を選択してください。" onClick={profileExplorer} /></p>
                                <input type="file" name="profile" id="selectprofile"  onChange={changeProfile} />
                            </div>
                        </div>
                </div>
                </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group ">
                        <label htmlFor="exampleInputEmail1" className="input-required">アカウントID　（メールアドレス）:</label> 
                        <input type="text" className="form-control" maxLength={50} name="email"  value={user_model['email']}  onChange={updateInput} />
                    </div>       
                </div>
            </div>                  
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="input-required">漢字氏名:</label> 
                        <input type="text" className="form-control" maxLength={40} name="name_jp"  value={user_model['name_jp']}  onChange={updateInput} />
                    </div>          
                </div>                      
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="input-required">カタカナ名:</label> 
                        <input type="text" className="form-control" maxLength={40} name="kana_name"  value={user_model['kana_name']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="input-required">パスワード:</label> 
                        <input type="password"  placeholder="******" className="form-control" maxLength={30} name="password"  value={user_model['password']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1" className="input-required">電話番号:</label> 
                        <input type="number" className="form-control" name="tel_no" maxLength={20} onKeyPress={inputNumber} value={user_model['tel_no']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">会社名:</label> 
                        <input type="text" className="form-control" name="company"  maxLength={70} value={user_model['company']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">部門名:</label> 
                        <input type="text" className="form-control" name="dept_name" maxLength={80} value={user_model['dept_name']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">役職:</label> 
                        <select name="position"  className="form-control"  onChange={onSelect} value={user_model["position"]} >
                            <option value="担当">担当</option>
                            <option value="主任">主任</option>
                            <option value="課長">課長</option>
                            <option value="部長">部長</option>
                            <option value="取締役">取締役</option>
                            <option value="副社長">副社長</option>
                            <option value="社長">社長</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 input-box">
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">担当エリア:</label> 
                        <input type="text" className="form-control" name="respond_area" maxLength={80}  value={user_model['respond_area']}  onChange={updateInput} />
                    </div>
                </div>
            </div>
            {
                (() => {
                    if(([7,8].includes(login_user.role))) {
                    return(
                        <div className="row">
                            <div className="col-12 input-box">
                                <div className="form-group">
                                    <input type="radio" className="mr_0" id="radio_type_case" name="role" value="8" checked ={(user_model['role'] == "8")? true: false} onChange={e => handleRadio(e)} />
                                    <label htmlFor="type" className="mr-3">案件を登録したいです。(要員は全部見れます。)</label><br/>
                                    <input type="radio" className="mr_0" id="radio_type_person" name="role" value="7" checked ={(user_model['role'] == "7")? true: false}  onChange={e => handleRadio(e)} />
                                    <label htmlFor="radio_type_base" className="mr-3">要員を登録したいです。(案件は全部見れます。)</label>
                                </div>
                            </div>
                        </div>
                    )
                    }
                })()
            }
           
            <div className="row">
                <div className="form-group mt-5 text-center">
                    <button onClick={storeProfile} className="button submit-btn btn_standard">更新</button>
                    <button type="button" className="modal_cancel" onClick={handleClose}>取消</button>

                </div>
            </div>
        </form>
                
    </div>
)
  return (
    <div className='personal-info'>
    <div className=''>
        <Dropdown>
            <Dropdown.Toggle>
                <a className="nav-link d-flex align-items-center" href="#"
                        id="navbarDropdownMenuLink"
                        role="button"
                        data-mdb-toggle="dropdown"
                        aria-expanded="false">
                        <span className="p-2">  {username}</span>
                        <ResourceImg remoteSrc={(localStorage.getItem('profile_image') !== undefined && 
                            localStorage.getItem('profile_image') !== null &&
                            localStorage.getItem('profile_image') !== '')?`profiles/${localStorage.getItem('profile_image')}`:''} src={'images/businessman.jpg'} className="rounded-circle" height="30" width="30" alt="icons"  />
                        
                </a>
            </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href="#" onClick={showProfile}>
                        プロフィール変更
                    </Dropdown.Item>
                    <Dropdown.Item href="#" onClick={logout}>
                        ログアウト
                    </Dropdown.Item>
                </Dropdown.Menu>
        </Dropdown>
      
    </div>

    <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{display: { xs: 'none', sm: 'block' }}} // Ensure the modal is on top
        >                                
            <Box sx={PCModalStyle}>
                {profile_modal}
            </Box>
        </Modal>  

         <Modal
            keepMounted
            open={open}
            onClose={handleClose}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            sx={{ display: { xs: 'block', sm: 'none' }}} // Ensure the modal is on top
        >                                
            <Box sx={MobileModalStyle}>
                {profile_modal}
            </Box>
        </Modal>   
    </div>
  );
};

export default DropDownList;