import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
const token = localStorage.getItem("token"); // retrieve stored user information from local storage
const header =  {
    headers :  {
        'Authorization': `Bearer ${token}` ,
        // "server_url" : `${SERVER_URL}`,
        "client-domain" :  `${CLIENT_DOMAIN}`,
        "Access-Control-Allow-Origin": "*" ,
       } 
};
class UserService {

    list(perPage:number, page:number,search_keywords:any,sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/users/list?list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, header);
    }   
    memberList(perPage:number, page:number,search_keywords:any,sort:any, column:string, order:string){
        let dataURL:string = API_URL + `/members/list?list_length=${perPage}&page=${page}&search_keywords=${search_keywords}&sort=${sort}&column=${column}&order=${order}`;
        return axios.get(dataURL, header);
    }   
    store(data:any) {
        console.log(data);
        let dataURL:string = API_URL + "/users/store1";
        return axios.post(dataURL,data, header);
    }
    update(data:any ,id:any) {
        let dataURL:string = API_URL + "/users/update1/"+id;
        return axios.post(dataURL,data, header);
    }
    showUser(user_id:any){
        let dataURL:string = API_URL +`/users/detail/${user_id}`;
        return axios.get(dataURL, header);
    }
    deleteList(data:any){
        let dataURL:string = API_URL+"/users/list/destroy1";
        return axios.post(dataURL,data,header);
    }
    updateProfile(data:any , photo_name:any){
        let dataURL:string = API_URL+"/users/profile/update1";
        if(photo_name != ""){
            data.photo = photo_name;
        }
        return axios.post(dataURL,data,header);
    }

}

  export default new UserService();